<template>
  <div class="flex justify-center bg-light">
    <rb-footer class="container">
      <rb-footer-link
        v-for="item in footerLinks"
        :key="item.id"
        :href="item.href"
        @click.prevent="() => navigateTo(item.href, { external: true, open: { target: '_blank' } })"
        >{{ $t(`footer.link.${item.name.replace(' ', '_').toLowerCase()}.name`) }}</rb-footer-link
      >
    </rb-footer>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n();
const footerLinks = [
  {
    id: 1,
    name: 'Pricing',
    href: t('footer.link.pricing.url'),
  },
  {
    id: 2,
    name: 'Careers',
    href: t('footer.link.careers.url'),
  },
  {
    id: 3,
    name: 'Help Center',
    href: t('footer.link.help_center.url'),
  },
  {
    id: 4,
    name: 'Terms',
    href: t('footer.link.terms.url'),
  },
  {
    id: 5,
    name: 'Privacy',
    href: t('footer.link.privacy.url'),
  },
  {
    id: 6,
    name: 'Legal',
    href: t('footer.link.legal.url'),
  },
];
</script>
<script lang="ts">
export default {
  name: 'BaseFooter',
};
</script>
