<template><div></div></template>

<script setup lang="ts">
const toastStore = useToastStore();
const { toasts } = storeToRefs(toastStore);
const { t } = useI18n();
const nuxtApp = useNuxtApp();

watch(
  toasts,
  () => {
    if (toasts.value.length > 0) {
      const toast = toastStore.popToast();
      if (toast) {
        nuxtApp.vueApp.config.globalProperties.$notify({
          type: toast.type,
          title: t(toast.title, toast.options.titleData as RbToastOptionsData),
          description: Array.isArray(toast.description)
            ? toast.description
            : t(toast.description as string, toast.options.descriptionData as RbToastOptionsData),
          autoClose: toast.options.autoClose as boolean,
          iconName: toast.options.iconName as string,
          duration: toast.options.duration as number,
        });
      }
    }
  },
  { deep: true, immediate: true },
);
</script>
