<template>
  <nuxt-link #default="{ isActive, navigate }" :to="to" custom :external="external">
    <rb-navigation-button
      v-if="Object.keys(props.driverSettings).length === 0"
      :current="isActive"
      :icon-name="icon"
      :icon-alt-text="
        $t('sidebar.menu_item.' + name.replace(' ', '_').toLowerCase() + '.iconAltText')
      "
      :has-sub-menu="hasSubMenu"
      @click="
        !hasSubMenu
          ? external
            ? navigateTo(to, { external: external })
            : sidebarNavigateTo(navigate)
          : false
      "
    >
      {{ $t('sidebar.menu_item.' + name.replace(' ', '_').toLowerCase()) }}
      <template #sub-menu>
        <slot name="sub-menu"></slot>
      </template>
    </rb-navigation-button>
    <rb-navigation-button
      v-else
      v-driver-step="driverSettings"
      :current="isActive"
      :icon-name="icon"
      :icon-alt-text="
        $t('sidebar.menu_item.' + name.replace(' ', '_').toLowerCase() + '.iconAltText')
      "
      :has-sub-menu="hasSubMenu"
      @click="
        !hasSubMenu
          ? external
            ? navigateTo(to, { external: external })
            : sidebarNavigateTo(navigate)
          : false
      "
    >
      {{ $t('sidebar.menu_item.' + name.replace(' ', '_').toLowerCase()) }}
      <template #sub-menu>
        <slot name="sub-menu"></slot>
      </template>
    </rb-navigation-button>
  </nuxt-link>
</template>

<script setup lang="ts">
import type { PropType } from 'vue';
import type { NavigationFailure } from 'vue-router';

const props = defineProps({
  name: {
    type: String as PropType<string>,
    default: 'Home',
  },
  to: {
    type: String as PropType<string>,
    default: '/',
  },
  external: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  icon: {
    type: String as PropType<string>,
    default: '',
  },
  hasSubMenu: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  driverSettings: {
    type: Object as PropType<object>,
    default() {
      return {};
    },
  },
});

const layoutStore = useLayoutStore();

function sidebarNavigateTo(navigate: (e?: MouseEvent) => Promise<void | NavigationFailure>) {
  navigate();
  layoutStore.closeSidebar();
}
</script>
